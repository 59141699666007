<template>
  <div class="tools-home">
    <common-header class="tools-head"></common-header>
    <div style="height: 100px"></div>
    <div class="tool-list">
      <div class="tool-card" v-for="(tool, index) in tools" :key="index">
        <div class="tool-icon" :style="{ 'background-image': 'url(' + tool.icon + ')' }"></div>
        <div class="tool-content">
          <h3>
            <em>{{ tool.title }}</em>
          </h3>
          <div class="tool-desc">{{ tool.desc }}</div>
          <a :href="tool.path"><em>进入</em></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/common/Header'

export default {
  name: 'ToolsHome',
  components: {
    'common-header': Header
  },
  data() {
    return {
      tools: [
        {
          icon: require('@/assets/tools/json.jpg'),
          title: 'json 工具',
          desc: '在线编辑json,语法检查、格式化、压缩等',
          path: '/tools/json'
        },
        {
          icon: require('@/assets/tools/time.jpg'),
          title: '时间戳转换',
          desc: '在线时间戳转换,毫秒、秒、北京时间等',
          path: '/'
        }
      ]
    }
  },
  methods: {},
  mounted() {}
}
</script>

<style scoped>
.tools-home {
  width: 100%;
}

.tools-head {
  position: fixed;
  z-index: 999;
  box-shadow: 0 1px 40px -8px rgb(0 0 0 / 50%);
}

.tool-list {
  width: 50%;
  min-width: 800px;
  margin: 0 auto;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, auto);
  justify-content: space-evenly;
}

.tool-card {
  height: 150px;
  display: grid;
  grid-template-columns: 40% 60%;
  border: 1px solid #ccc;
  background-color: white;
  transition: box-shadow 0.3s ease-in-out;
}

.tool-card:hover {
  box-shadow: 0 3px 8px 0 rgb(0 0 0 / 20%);
}

.tool-icon {
  background-size: 90% 80%;
  background-repeat: no-repeat;
  background-position: center;
}

.tool-content {
  height: 80%;
  position: relative;
  padding: 10% 10px;
  text-align: left;
}

.tool-content h3 {
  color: #333;
}

.tool-desc {
  margin-top: 10px;
  height: 50%;
  color: #8a8f97;
  font-size: 14px;
  text-align: left;
}

.tool-content a {
  position: absolute;
  bottom: 10%;
  right: 10%;
  color: #333;
  font-size: 14px;
}

.tool-content a:hover {
  color: #fe9600;
}
</style>
