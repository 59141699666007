<template>
  <div class="common-header">
    <el-progress :percentage="progress" color="#fe9600" stroke-width="3" :show-text="false" />
    <div class="header-nav">
      <div class="nav-icon">
        <el-image
          style="height: 60px; width: 40%"
          :src="require('@/assets/default/head_log.png')"
          fit="fill"
        ></el-image>
      </div>
      <div class="nav-tabs">
        <a v-for="(tabs, index) in navTabs" :href="tabs.path" :key="index">
          <icon :icon-name="tabs.icon" :icon-title="tabs.title" />
        </a>
      </div>
      <div class="nav-search"></div>
    </div>
  </div>
</template>

<script>
import icon from '@/components/icon'

export default {
  name: 'Header',
  components: {
    icon
  },
  data() {
    return {
      progress: 0,
      keyword: '',
      navTabs: [
        {
          icon: 'HomeFilled',
          title: '首 页',
          path: '/'
        },
        {
          icon: 'IconMenu',
          title: '文 档',
          path: '/docs'
        },
        {
          icon: 'Tools',
          title: '工 具',
          path: '/tools'
        },
        {
          icon: 'Download',
          title: '下 载',
          path: '/'
        },
        {
          icon: 'Comment',
          title: '问 答',
          path: '/'
        },
        {
          icon: 'Flag',
          title: '认 证',
          path: '/'
        },
        {
          icon: 'HelpFilled',
          title: '其 他',
          path: '/'
        }
      ]
    }
  },
  methods: {
    // 定义方法
    scrollProgress() {
      // 变量 scrollHeight 是滚动条的总高度
      const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
      // 变量 windowHeight 是可视区的高度
      const windowHeight = document.documentElement.clientHeight || document.body.clientHeight
      // 变量scrollTop为当前页面的滚动条纵坐标位置
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      // 设置进度条
      this.progress = (scrollTop / (scrollHeight - windowHeight)) * 100
    },
    searchKeyword() {}
  },
  mounted() {
    // 页面渲染后调用
    window.addEventListener('scroll', this.scrollProgress)
  },
  beforeUnmount() {
    // 页面销毁前
    window.removeEventListener('scroll', this.scrollProgress)
  }
}
</script>

<style scoped>
.common-header {
  width: 100%;
  height: 70px;
  min-width: 1400px;
  background-color: #ffffff;
}

.header-nav {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-icon {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-tabs {
  font-size: 13px;
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-tabs a {
  height: 100%;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: transparent;
  text-decoration: none;
  transition: all 0.5s;
}

.nav-tabs a:hover {
  color: #fe9600;
  font-size: 15px;
  border-bottom: 4px solid rgba(254, 150, 0, 0.98);
  box-shadow: 0 25px 20px -20px #fe9600;
}

.nav-search {
  width: 40%;
  height: 100%;
}
</style>
